import React from 'react'
import { SEO } from '../components'
import { Homepage } from '../routes'

const index = () => {
  return (
    <>
      <SEO title={'Ursanex'} />
      <Homepage />
    </>
  )
}

export default index
